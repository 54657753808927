<template>
  <v-app dark>
    <v-main>
      <v-container>
          <slot />
      </v-container>
    </v-main>
    <v-footer :absolute="true" app class="justify-center">
      <div class="footer">
        &copy; 2020 - {{ new Date().getFullYear() }}. All rights reserved.
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>
